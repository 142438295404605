@import "colors";

/* Alerts */
.alert {
  -webkit-transition: all 200ms;
  -moz-transition: all 200ms;
  -ms-transition: all 200ms;
  -o-transition: all 200ms;
  transition: all 200ms;

}

.alert-warning {
  background-color: $warning-color;
  color: white !important;
}

.alert-info {
  background-color: $info-color !important;
  color: white;
}

.alert-success {
  background-color: $success-color !important;
  color: white;
}

.alert-error {
  background-color: $error-color !important;
  color: white;
}
