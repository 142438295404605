/* Primary color */
$primary-color: #05164d;
$primary-front-color: white;

$secondary-color: #787878;

$background-color: #FFFFFF;

$success-color: #00a65a;
$success-hover-color: darken($success-color, 10%);

$error-color: #dd4b39;
$error-hover-color: darken($error-color, 10%);

$info-color: #00c0ef;
$info-hover-color: darken($info-color, 10%);

$warning-color: #f39c12;
$warning-hover-color: darken($warning-color, 10%);

$link-color: #003399;

$navbar-color: #ffad00;

$sidebar-color: #ffad00;