@import "colors";

/* Buttons */
.btn {
  border-radius: 5px !important;
  border: 1px solid transparent;
  -webkit-transition: all 200ms;
  -moz-transition: all 200ms;
  -ms-transition: all 200ms;
  -o-transition: all 200ms;
  transition: all 200ms;
  &:hover,
  &:active,
  &:focus{
    opacity: 1;
  }
}

.btn.btn-default, .btn.btn-invert {
  background-color: $background-color !important;
  color: $primary-color !important;
  border: 1px solid darken($background-color, 10%) !important;
  &:hover,
  &:active,
  &:focus{
    border-color: darken($background-color, 20%) !important;
  }
}

.btn.btn-primary {
  background-color: $primary-color !important;
  color: white !important;
  border-color: transparent !important;
  &:hover,
  &:active,
  &:focus{
    background-color: $secondary-color !important;
    border-color: darken($secondary-color, 20%) !important;
  }
}

.btn.btn-warning {
  background-color: $warning-color;
  color: white !important;
  border-color: darken($warning-color, 20%) !important;
  &:hover,
  &:active,
  &:focus{
    background-color: $warning-hover-color;
    border-color: darken($warning-hover-color, 20%) !important;
  }
}

.btn.btn-info {
  background-color: $info-color !important;
  color: white;
  border-color: darken($info-color, 20%) !important;
  &:hover,
  &:active,
  &:focus{
    background-color: $info-hover-color;
    border-color: darken($info-hover-color, 20%) !important;
  }
}

.btn.btn-success {
  background-color: $success-color !important;
  color: white;
  border-color: darken($success-color, 20%) !important;
  &:hover,
  &:active,
  &:focus{
    background-color: $success-hover-color !important;
    border-color: darken($success-hover-color, 20%) !important;
  }
}

.btn.btn-error {
  background-color: $error-color !important;
  color: white;
  border-color: darken($error-color, 20%) !important;
  &:hover,
  &:active,
  &:focus{
    background-color: $error-hover-color !important;
    border-color: darken($error-hover-color, 20%) !important;
  }
}

.btn .btn-box-tool {
  background-color: $primary-color !important;
  border-color: darken($primary-color, 20%) !important;
  &:hover,
  &:active,
  &:focus {
    background-color: $secondary-color !important;
    border-color: darken($secondary-color, 20%) !important;
  }
}

.fpt-box2 button.btn-default:hover {
  background-color: $secondary-color !important;
  border-color: darken($secondary-color, 20%) !important;
}
