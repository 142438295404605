@import "../../layout/scss/colors";
@import "../../layout/scss/buttons";
@import "../../layout/scss/alerts";

$left-side-background-color: $sidebar-color;
$left-side-color: white;
$logotype-background-color: $primary-color;

.content-wrapper {
  background: $background-color;
}

.skin-blue header.main-header .navbar {
  background-color: $navbar-color;
  & .sidebar-toggle:hover {
    background-color: $secondary-color;
  }
}

body.skin-blue .main-sidebar,
body.skin-blue .left-side {
  background-color: $left-side-background-color;

  a {
    color: $left-side-color;
    &:hover,
    &:active,
    &:focus{
      color: darken($left-side-color, 50%);
      border-color: $primary-color;
    }
  }
  .sidebar-menu > li > a,
  .sidebar-menu > li.dropdown > a {
    color: $left-side-color;
    background-color: $left-side-background-color;
    &:hover,
    &:active,
    &:focus{
      color: darken($left-side-color, 20%);
      background-color: darken($left-side-background-color, 5%);
      border-left-color: $primary-color;
    }
  }
  .sidebar-menu > li.active > a {
    color: darken($left-side-color, 20%);
    background-color: darken($left-side-background-color, 10%);
    border-left-color: $primary-color;
    &:hover,
    &:active,
    &:focus {
      color: darken($left-side-color, 20%);
    }
  }

  .sidebar-menu > li > .treeview-menu {
    background-color: darken($left-side-background-color, 5%) !important;
    color: $left-side-color;
  }

  .sidebar-menu > li > .treeview-menu .active a {
    color: darken($left-side-color, 50%);
  }

  .dropdown-menu{
    background-color: lighten($primary-color, 50%);
    color: $primary-color !important;
    a
    &:hover,
    &:active,
    &:focus {
      background-color: lighten($primary-color, 70%) !important;
    }
  }
}

.skin-blue .user-panel > .info, .skin-blue .user-panel > .info > a {
  color: $left-side-color;
}

.skin-blue .main-header .logo:hover, .skin-blue .main-header .logo{
  background-color: $primary-color;
}

.avatar{
  background-color: $background-color;
}

.progress-bar {
  background-color: $primary-color !important;
  border-color: darken($primary-color, 20%) !important;
  color: lighten($primary-color, 70%) !important;
}

.fc-toolbar .fc-today-button{
  background-color: $primary-color !important;
  border-color: $primary-color !important;
  color: white;
  &:hover,
  &:active,
  &:focus{
    background-color: darken($primary-color, 5%) !important;
    border-color: darken($primary-color, 5%) !important;
  }
}

.media.sell .conditions {
  color: darken($primary-color, 20%) !important;
}

a {
  color: $link-color;
  &:hover,
  &:active,
  &:focus{
    color: darken($link-color, 20%);
  }
}

.media.sell i {
  color: $primary-color;
}

.media.sell a i {
  color: $link-color;
}

.nav-tabs-custom > .nav-tabs > li.active {
  border-top-color: $primary-color;
}

.dashboard-box .media-left .fa {
  color: $primary-color;
}

.services-payment > .form-group > .btn-group > a.btn.active.btn-primary {
    background-color: $primary-color !important;
    border: 1px solid darken($primary-color, 20%);
    &:hover,
    &:active,
    &:focus{
        background-color: $primary-color !important;
    }
}